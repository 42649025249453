<template>
  <q-table
    flat
    bordered
    :data="reports"
    row-key="transaction_id"
    :columns="conversionColumns"
    :pagination="pagination"
    :visible-columns="visibleColumns"
  >
    <template v-slot:top-right>
      <q-btn
        outline
        color="grey-3"
        size="12px"
        text-color="grey-8"
        icon-right="archive"
        class="q-mr-sm q-py-xs"
        no-caps
        @click="exportCsvClickReport()"
        unelevated
        :disable="reqPagination.total == 0 || reqPagination.total > 100000"
      >
        <q-tooltip>
          {{ reqPagination.total > 100000 ? 'Limite de 100.000 linhas p exportar' : 'Exportar como CSV' }}
        </q-tooltip>
      </q-btn>
      <q-select
        v-model="visibleColumns"
        multiple
        outlined
        dense
        options-dense
        :display-value="$t('customize_columns')"
        emit-value
        map-options
        :options="conversionColumns"
        option-value="name"
        options-cover
        style="min-width: 150px"
      >
        <template v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }">
          <q-item v-bind="itemProps" v-on="itemEvents" @click="saveLocalColumns(itemProps.active, opt.name)">
            <q-item-section>
              <q-item-label v-html="opt.label"></q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-toggle :value="selected" @input="toggleOption(opt)" />
            </q-item-section>
          </q-item>
        </template>
      </q-select>
    </template>
    <template v-slot:bottom>
      <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
        Total:{{ reqPagination.total }}
        <q-space />
        <q-pagination
          unelevated
          v-model="currentPage"
          color="primary"
          :max="reqPagination.last_page"
          :max-pages="6"
          boundary-numbers
          direction-links
        />
      </q-toolbar>
    </template>
  </q-table>
</template>

<script>
import ReportMixin from '../../mixins/ReportMixin';
export default {
  name: 'ListConversionReports',
  mixins: [ReportMixin],
  watch: {
    async currentPage() {
      if (this.reqPagination.from != null && this.reqPagination.from != this.reqPagination.current_page) {
        await this.getConversionReport(`page=${this.currentPage}`, `%26per_page=${this.pagination.rowsPerPage - 1}`);
        if (this.reports.length > 0) {
          this.updateReports();
        }
      }
    },
    visibleColumns() {
      if (this.reports && this.reports[10]) {
        if (this.visibleColumns.includes('campaign_id')) {
          this.reports[10].campaign_id = 'TOTAL:';
          this.reports[10].campaign_name = '';
        } else {
          this.reports[10].campaign_id = '';
          this.reports[10].campaign_name = 'TOTAL:';
        }
      }
    }
  },

  created() {
    this.ActionSetFilterReports(
      `test_campaigns=0` +
        '%26start_date=' +
        this.$moment().subtract(1, 'days').format('YYYY-MM-DD') +
        '%26end_date=' +
        this.$moment().format('YYYY-MM-DD')
    );
    this.ActionSetReports();
    this.getLocalStorage = Object.entries(localStorage);
    this.localColumns = Object.fromEntries(this.getLocalStorage);
    function getKey(columns) {
      const arr = [],
        obj = Object.keys(columns);
      for (var x in obj) {
        if (columns[obj[x]] == 'true') {
          arr.push(obj[x]);
        }
      }
      return arr;
    }

    if (getKey(this.localColumns) && getKey(this.localColumns).length != 0) {
      this.visibleColumns = getKey(this.localColumns);
    } else {
      this.visibleColumns = [
        'campaign_name',
        'site_name',
        'order_id',
        'transaction_id',
        'creative_type',
        'transaction_amount',
        'full_commission_amount',
        'affiliate_commission_cost',
        'afilio_commition_margin',
        'xtrasParams1',
        'click_datetime',
        'transaction_datetime',
        'time_diff',
        'ip',
        'validation_status',
        'source_url',
        'first_url'
      ];
    }
    for (var x in this.visibleColumns) {
      localStorage.setItem(this.visibleColumns[x], true);
    }

    this.getConversionReport(null, '%26per_page=' + this.pagination.rowsPerPage - 1);
  },

  data() {
    return {
      visibleColumns: null,
      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 11
      }
    };
  },

  methods: {
    async exportCsvClickReport() {
      let columnsCsv = this.conversionColumns.filter((item) => this.visibleColumns.includes(item.name));

      this.onLoading(true);
      let filter = '';
      if (this.filterReports !== undefined) filter = this.filterReports.replace('id_affiliate', 'affiliate_id');
      try {
        const { data, status } = await this.$http.get('redirect?app=report&path=/api/get/report/export_csv?' + filter);
        if (status === 200 && !data.message) {
          this.exportTableCsv(data, columnsCsv, 'conversion_reports');
          this.successNotify('Exportado com sucesso!');
        } else this.successNotify(data.message);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    labelStatus(status) {
      switch (status) {
        case 0:
          return 'Recusado';
        case 1:
          return 'Pendente';
        case 2:
          return 'Aprovado';
        case 3:
          return 'Faturado';
        case 4:
          return 'Pago';
        default:
          return '';
      }
    },
    saveLocalColumns(itemPropsActive, optName) {
      localStorage.setItem(optName, !itemPropsActive);
    },
    updateReports() {
      this.reports.splice(10, 0, {
        campaign_name: 'TOTAL:',
        transaction_amount: this.parseMoney(this.reportsTotal[0].transaction_amount),
        full_commission_amount: this.parseMoney(this.reportsTotal[0].full_commission_amount),
        affiliate_commission_cost: this.parseMoney(this.reportsTotal[0].affiliate_commission_cost),
        afilio_commition_margin: this.parseMoney(this.reportsTotal[0].afilio_commition_margin),
        created_at: this.$moment().format('YYYY-MM-DD')
      });

      this.$nextTick(() => {
        const rows = document.querySelectorAll('.q-table tbody tr');
        rows.forEach((row) => {
          const columns = row.querySelectorAll('td');
          columns.forEach((column) => {
            if (column.innerText.trim() === 'TOTAL:') {
              row.classList.add('total-row');
            }
          });
        });
      });
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  },
  mounted() {
    this.getConversionReport().then(() => {
      this.updateReports();
    });
  }
};
</script>
<style>
.total-row td {
  font-weight: bold;
}
</style>
